import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {CssBaseline, AppBar, Toolbar, Typography, Container, Grid, LinearProgress, Button} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import React from 'react';

import {ProfileForm, ProfileFormData} from './ProfileForm';
import {ResultList, ScanResultItem} from './ResultList';

type ScanResponseItem = {
  broker_name: string;
  search_result_url: string;
  profiles: Array<{
    url: string;
    match_rate: number;
  }>;
};

type ScanRequest = {
  first_name: string;
  middle_name?: string;
  last_name: string;
  birth_date?: string;
  state: string;
  city: string;
  phone?: string;
  email?: string;
};

export const App = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [profileData, setProfileData] = React.useState<ProfileFormData>({
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: dayjs('1996-03-28'),
    state: '',
    city: '',
    phone: '',
    email: '',
  });
  const [results, setResults] = React.useState<Array<ScanResultItem> | null>(null);

  const makeRequest = (data: ProfileFormData) => {
    setIsLoading(true);
    setProfileData(data);

    const payload: ScanRequest = {
      first_name: data.firstName,
      last_name: data.lastName,
      state: data.state,
      city: data.city,
    };

    if (data.birthDate !== null) {
      payload['birth_date'] = data.birthDate.format('YYYY-MM-DD');
    }

    if (data.phone) {
      payload['phone'] = data.phone;
    }

    if (data.email) {
      payload['email'] = data.email;
    }

    if (data.middleName) {
      payload['middle_name'] = data.middleName;
    }

    axios
      .post('/api/v1/scan', payload)
      .then(r => {
        if (r.status !== 200) {
          throw new Error('Invalid response');
        }

        setResults(
          r.data.map((i: ScanResponseItem) => ({
            brokerName: i.broker_name,
            searchResultUrl: i.search_result_url,
            profiles: i.profiles.map((p: {url: string; match_rate: number}) => ({
              url: p.url,
              matchRate: p.match_rate,
            })),
          })),
        );
      })
      .catch(() => window.alert('Oops, something went wrong. Please try again later.'))
      .finally(() => setIsLoading(false));
  };

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
              Data Broker Scanner
            </Typography>
            <Button
              color="inherit"
              href="https://aura.slack.com/archives/C03FUC9MTE2"
              target="_blank"
              endIcon={<OpenInNewIcon />}
            >
              {'Report a problem'}
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg">
          <Grid container direction="column" padding={2} spacing={2}>
            <Grid item>
              <ProfileForm disabled={isLoading} initialData={profileData} onSubmit={makeRequest} />
            </Grid>
            {isLoading && (
              <Grid item>
                <LinearProgress />
              </Grid>
            )}
            {results && (
              <Grid item>
                <ResultList resultItems={results} />
              </Grid>
            )}
          </Grid>
        </Container>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default App;
