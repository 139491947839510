import {List, ListItemText, Typography, Link, Divider, Grid, ListItem} from '@mui/material';
import React from 'react';

export type ScanResultItem = {
  brokerName: string;
  searchResultUrl: string;
  profiles: Array<{
    url: string;
    matchRate: number;
  }>;
};

export type ResultListProps = {
  resultItems: Array<ScanResultItem>;
};

export const ResultList = (props: ResultListProps) => {
  return (
    <List dense>
      {props.resultItems.map((r, i) => (
        <React.Fragment key={i}>
          <ListItem divider>
            <ListItemText
              primary={
                <React.Fragment>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="h6">{r.brokerName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" align="right">
                        {`(${r.profiles.length} profiles)`}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              secondary={
                <Typography variant="body2">
                  {`Search result: `}
                  <Link target="_blank" href={r.searchResultUrl}>
                    {r.searchResultUrl}
                  </Link>
                </Typography>
              }
            />
          </ListItem>
          {r.profiles.map((p, j) => (
            <ListItem sx={{pl: 4}} key={j}>
              <ListItemText
                primary={
                  <Link key={j} target="_blank" href={p.url}>
                    <Typography variant="subtitle2">{p.url}</Typography>
                  </Link>
                }
                secondary={<Typography variant="subtitle2">{`Match rate: ${p.matchRate}`}</Typography>}
              />
            </ListItem>
          ))}
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};
